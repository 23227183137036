import { drawerWidth } from '../styles.js';
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "150px",
    [theme.breakpoints.down(959)]: {
      marginTop: "0px",
    }
  },
  boxhero: {
    padding: "160px 80px 0px 80px",
    [theme.breakpoints.down(959)]: {
      padding: "50px 40px 50px 40px",
    }
  },
  boxform: {
    padding: "80px",
    [theme.breakpoints.down(959)]: {
      padding: "40px",
    }
  }
}));
