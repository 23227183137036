import { Box, Container } from "@material-ui/core";

import Hidden from "./HiddenSSRCompatible";
import { Loading } from "@microrealestate/commonui/components";

function PageContent({ maxWidth, noMargin, children, marginXMobile }) {
  return (
    <>
      <Hidden smDown>
        <Box ml={7}>
          <Container maxWidth={maxWidth}>
            <Box my={2}>{children}</Box>
          </Container>
        </Box>
      </Hidden>
      <Hidden mdUp>
        {noMargin ? (
          <div>{children}</div>
        ) : (
          <Box mb={10} mx={marginXMobile}>
            {children}
          </Box>
        )}
      </Hidden>
    </>
  );
}

function Page({
  children,
  ActionBar,
  noMargin,
  marginMobile = 2,
  maxWidth = "lg",
  loading = false,
}) {
  return (
    <PageContent
      noMargin={noMargin}
      maxWidth={maxWidth}
      marginXMobile={marginMobile}
    >
      {marginMobile !== 2 ? (
        <></>
      ) : (
        <Box mb={noMargin ? 0 : 2}>{ActionBar}</Box>
      )}
      {loading ? <Loading fullScreen /> : children}
    </PageContent>
  );
}

export default Page;
